import { Box } from "@chakra-ui/react";

import { NotFoundError } from "@/components/error";
import { AuthenticatedLayout, Layout } from "@/components/layouts";
import { useSession } from "@/hooks";

const NotFound = () => (
  <Box
    px={{ base: 4, md: 8 }}
    py={{ base: 10, md: 20 }}
    minH="100vh"
    width="100%"
    bg="grey.25"
  >
    <NotFoundError />
  </Box>
);

const Custom404 = () => {
  const { isAuthenticated } = useSession();

  if (isAuthenticated) {
    return (
      <AuthenticatedLayout title="Page not found">
        <NotFound />
      </AuthenticatedLayout>
    );
  }

  return (
    <Layout title="Page not found">
      <NotFound />
    </Layout>
  );
};

export default Custom404;
